import './about.css'

const About = () => {
    return (
        <div className="about-container section">
            <div className="title">
                <span className="hide">
                    Who We Are
                </span>
            </div>
        </div>
    );
}
export default About;